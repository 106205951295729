/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './payStyle';
import React from 'react';

import { SiteLink, usePost } from '../../../components';
import { Button, ErrorMessage } from '../../../components';
import env from '../../../env';
import { useRouter } from '../../../components';
import { initStripe } from './payStripe';

import { CartSummary } from '../cartSummary/summary';

export function Pay({ cart, model, form, payStripe }) {

  const [stripe, setStripe] = React.useState(null);
  const [card, setCard] = React.useState(null);
  const [error, setError] = React.useState(null);

  const post = usePost();
  const { Redirect } = useRouter()

  React.useEffect(() => {
    const { stripe, card } = initStripe(setError)
    setStripe(stripe);
    setCard(card);
  }, [])

  function payNow() {
    if (error) return;
    stripe.createToken(card).then(function (result) {
      if (result.error) {
        setError(result.error.message);
      } else {
        setError(null);
        // Send the token to your server.
        onSubmit(result.token.id);
      }
    });
  }

  function onSubmit(tokenId) {
    console.log(tokenId)
    post.send(env.apiBase + "/api/cart/payStripe", { tokenId: tokenId, itemTotalAmount: cart.itemTotalAmount, cartId: env.getDevCartId() });
  }

  if (post.done()) {
    env.setDevCartId(null);
    return <Redirect to={`/order-thankyou?o=${post.response.results.OrderIdToken}`} />
  }

  return <div css={style.pay}>
    <div className="row" >
      <div className="col-md-9">
        <h3>Pay by Credit Card</h3>

        <div css={style.stripeWrapper}>
          <div className="stripe-wrapper">
            <div id="card-element"></div>
          </div>
          <div css = {style.paymentInfoText}>
            Payment through encrypted gateway can be made by Visa, Mastercard or AMEX.  Please enter your credit card details to complete your order. 
            For alternative payment methods please <SiteLink to = "#contactForm" className="contact-link"> contact us </SiteLink>
          </div>
        </div>

      </div>

      <div className="col-md-3">
        <CartSummary cart={cart} />
      </div>
    </div>
    <div css={style.formActions}>
      <Button onClick={payNow} status={post.status}>Pay Now</Button>
      <ErrorMessage errors={error} />
      <ErrorMessage errors={post.errors} />
    </div>
  </div>
}
