import { mq } from "../../../cssInJs";

export default {
  pageTiles_small3: {
    margin: "auto",
    marginTop: "0",
    marginBottom: "2rem",
  },

  pageTiles_small3_warpper: mq({
    display: "flex",
    // marginLeft: "-0.5rem",
    // marginRight: "-0.5rem",
    flexWrap: "wrap",
    "&.useSlider": {
      display: "block",
      marginLeft: "0",
      marginRight: "0",
    },
  }),

  pageTile_small3: mq({
    flex: ["0 0 50%", null, "0 0 16.6%", null, null, "0 0 16.6%"],
    maxWidth: ["50%", null, "16.6%", null, null, "16.6%"],
    padding: "0.5rem 1rem",
    "&.useSlider": {
      maxWidth: "372px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    ".pageTile_small3_link": {
      boxShadow: "6px 8px 9px 3px rgba(190,190,190,0.5)",
      display: "block",
      borderRadius: "20px",
      // overflow: "hidden",
      background: "#F8F8F8",
      "&:hover": {
        textDecoration: "none",
        ".pageTile_small3__bg": {
          transform: "scale(1.05)",
        },
      },
    },
    ".pageTile_small3__bg": {
      paddingTop: "80%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      transition: "all .2s",
      borderRadius: "20px 20px 0 0",
    },
    ".pageTile_small3__title": {
      margin: ".5rem 1rem 1rem",
      color: "black",
      WebkitLineClamp: "1",
      overflow: "hidden",
      display: "-webkit-box",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      height: "2.5rem",
      lineHeight: "2",
      "svg": {
        marginLeft: "0.5rem",
        marginTop: "-2px",
      },
    },
  }),
};
