/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";

export function DesignServicesThankyou({ changeOptionItems }) {
    return (
      <div
        className="popup__common"
        css={[style.popup__common, style.design__services__enquiry]}
      >
        <div className="dse__img__container">
          <img src="/assets/logos/logo.png" width="100%" alt="logo" />
        </div>
        <div className="dse__title">Design Services Enquiry</div>
        <div
          style={{
            textAlign: "center",
            marginTop: "2rem",
            fontSize: "2rem",
            fontWeight: 600,
          }}
        >
          Thank you
        </div>
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          Your enqiry has been received. <br /> We will be in touch contact you
          soon.
        </div>
        <div
          style={{ display: "flex", marginTop: "3rem" }}
          className="thank__buttons"
        >
          <div className={`thank__button`} style={{ textAlign: "right" }}>
            <button
              onClick={() => {
                changeOptionItems("closepopup");
              }}
              type="button"
              className="btn btn-primary"
            >
              Cancel
            </button>
          </div>
          <div className={`thank__button`} style={{ textAlign: "left" }}>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                changeOptionItems("backtohome");
              }}
            >
              Back to Home
            </button>
          </div>
        </div>
      </div>
    );
  }