/** @jsx jsx */
import { jsx } from "@emotion/core";
//import React from "react";
import cmsUtils from "../../cmsUtils";
import utils from "utils";
import { SiteLink } from "components";
import style from "./cardTilesStyle";
import Slider from "react-slick";
import { MdOutlineArrowForward } from "react-icons/md";

export function CardTiles(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const subItems = item.items || [];
  const cssClass = utils.classNames(
    "cms_item",
    "card__tiles",
    item.cssClass || item.anchorName || ""
  );

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: subItems.length >= 2 ? 2 : subItems.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: subItems.length >= 2 ? 2 : subItems.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: subItems.length >= 1 ? 1 : subItems.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cardTiles = subItems.map((item, index) => {
    return <CardTile key={index} item={item} />;
  });

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.card__tiles}
    >
      <Slider {...settings}>{cardTiles}</Slider>
    </div>
  );
}

function CardTile(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "card__tile",
    item.cssClass || item.anchorName || ""
  );

  const imageUrl = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));
  const linkUrl = cmsUtils.payload(item, "LinkUrl");
  const title = cmsUtils.payload(item, "Title");
  const des = cmsUtils.payload(item, "Des");
  const bottomText = cmsUtils.payload(item, "BottomText");

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.card__tile}
    >
      <SiteLink className='card__tile__link' to={linkUrl}>
        <div
          style={{
            overflow: "hidden",
            position: "relative",
          }}
          className='card__tile__con'
        >
          <div
            className='card__tile__bg'
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          >
            {/*  */}
          </div>

          <div className='card__tile__detail'>
            <div className='card__tile__title'>{title}</div>
            <div className='card__tile__des'>{des}</div>
            <div className='card__tile__bottom'>
              {bottomText}
              <MdOutlineArrowForward />
            </div>
          </div>
        </div>
      </SiteLink>
    </div>
  );
}
