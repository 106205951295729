/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./almostFinishedStyle";
import { MdClose, MdFileUpload } from "react-icons/md";
import { Button } from "../../../../components";

export function AlmostFinished({ changeOptionItems, option }) {
  return (
    <div
      className="popup__almost__finished popup__common"
      css={[style.popup__common, style.popup__almost__finished]}
    >
      <div className="popup__common__title"> AlmostFinished </div>
      <MdClose
        className="close__btn"
        onClick={() => {
          changeOptionItems("closepopup");
        }}
      />
      <div style={{ padding: "1rem" }}>
        <div>Please upload design to continue</div>
        <div className="button__container" style={{ marginTop: "0.5rem" }}>
          <div
            className={`button__1 ${
              option.design === "Need Design Services" ? "selected" : ""
            }`}
          >
            <Button
              onClick={() => {
                changeOptionItems("design", { type: "Need Design Services" });
              }}
            >
              {" "}
              Need Design Services?{" "}
            </Button>
          </div>
          <div
            className={`button__1 ${
              option.design === "Upload your design" ? "selected" : ""
            }`}
          >
            <Button
              onClick={() => {
                changeOptionItems("design", { type: "Upload your design" });
              }}
            >
              {" "}
              Upload your design!{" "}
              <MdFileUpload style={{ marginLeft: "1rem" }} />{" "}
            </Button>
          </div>
        </div>
      </div>
      <div style={{ padding: "1rem" }}>
        <div> Do you require delivery? </div>
        <div className="button__container" style={{ marginTop: "0.5rem" }}>
          <div
            className={`button__1 ${
              option.delivery === "No" ? "selected" : ""
            }`}
          >
            <Button
              onClick={() => {
                changeOptionItems("delivery", { type: "No" });
              }}
            >
              {" "}
              No{" "}
            </Button>
          </div>
          <div
            className={`button__1 ${
              option.delivery === "Yes" ? "selected" : ""
            }`}
          >
            <Button
              onClick={() => {
                changeOptionItems("delivery", { type: "Yes" });
              }}
            >
              {" "}
              Yes{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
