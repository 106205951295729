/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Fragment, useState } from "react";
import env from "../../../env";
import { Loading, ErrorPopup, usePost } from "../../../components";
//import { useForm } from "../../../form";
import { ProductTile } from "./productTile";
import { tileListStyle } from "./productTileStyle";
//import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
//import utils from "utils";
//import { getCategoryListModel } from "./categoryListModel";

export function ProductItems({ categories }) {
  const post = usePost();
  React.useEffect(() => {
    post.send(env.apiBase + "/api/product/getProducts", {
      categories: categories,
    });

    // eslint-disable-next-line
  }, []);

  if (post.loading()) {
    return <Loading />;
  } else if (post.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={post.errors} />;
  }

  const data = post.response;

  return data.items && data.items.length > 0 ? (
    <Items
      items={data.items}
      categories={data.categories}
      categoryF={categories}
    />
  ) : (
    <div>No products</div>
  );
}

function Items({ items, categories, categoryF }) {
  const s = tileListStyle;
  const [sort, setSort] = useState("latest");
  const [showProducts, setShowProducts] = useState(sortProudcts(sortProudcts(items, "latest")));
  const [trigger, setTrigger] = useState(0);
  const [category, setCategory] = useState(categoryF);
  const [pending, setPending] = useState(false);
  const [openBtn, setOpenBtn] = useState(false);
  
  const post = usePost();
  //const isDesktop = utils.css.screenWidth() > 576;

  function handleChange(event) {
    const _value = event.target.value;
    setCategory(_value);
    setTrigger(trigger + 1);
  }

  function handleChange2(event) {
    const _value = event.target.value;
    setShowProducts([...sortProudcts(items, _value)]);
    setSort(_value);
  }

  React.useEffect(() => {
    if (trigger === 0) {
      return;
    }

    setPending(true);
    post.send(env.apiBase + "/api/product/getProducts", {
      categories: category,
    });

    // eslint-disable-next-line
  }, [trigger]);

  if (post.done()) {
    const data = post.response;
    setPending(false);
    setShowProducts(data.items);
    post.reset();
  }

  function handleBtnClick() {
    setOpenBtn(!openBtn);
  }

  // function orderItemChanged(e, control) {
  //   if (control && control.name === "categoryList") {
  //     console.log("controll value", control.value);
  //     let _items = [];
  //     if (control.value && control.value !== "") {
  //       _items = items.filter((item) => {
  //         if (control.value === "_favourites_") {
  //           if (localStorage.favorites !== "") {
  //             if (localStorage.favorites.includes(item.productId)) {
  //               return true;
  //             }
  //           }
  //         } else {
  //           for (let i in item.productCategories) {
  //             if (item.productCategories[i].categoryId === control.value) {
  //               item.seqInCategory = item.productCategories[i].seq;
  //               return true;
  //             }
  //           }
  //         }
  //         return false;
  //       });
  //     } else {
  //       _items = items;
  //     }
  //     //sort
  //     sortProudcts(_items);
  //     setShowProducts(_items);
  //   }
  // }

  //const render = (name) => categoryListForm.renderControl(name, null);

  return (
    <Fragment>
      <div css={s.p_f_warpper} className='p_f_warpper'>
        {/* <div css={s.filter__warpper} className='filter__warpper'>
          <div
            style={{
              marginBottom: "1rem",
              fontWeight: 600,
              position: "relative",
            }}
            className='all_cate_btn_con'
          >
            <div className='all_cate_des'>
              All Categories
              <MdKeyboardArrowUp
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "0px",
                }}
              />
            </div>
            <div className='all_cate_mob'>
              <Button className='all_cate_mob_btn' onClick={handleBtnClick}>
                All Categories
                <MdKeyboardArrowDown
                  size={25}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: "5rem",
                  }}
                />
              </Button>
            </div>
          </div>
          {(isDesktop || openBtn) && (
            <div className='all_cate_item_con'>
              {categories.map((categoryItem, index) => {
                return (
                  <div className='form-group' css={s.form__group} key={index}>
                    <div className='form-check false'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='rightSortInput'
                        id={`rightSortInput_${index}`}
                        value={categoryItem.categoryId}
                        onChange={handleChange}
                        checked={category === categoryItem.categoryId && true}
                      />
                      <label
                        className='form-check-label'
                        htmlFor={`rightSortInput_${index}`}
                      >
                        {categoryItem.categoryName}
                        <span>{`(${categoryItem.numberOfProducts})`}</span>
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.2)" }} />
        </div> */}
        <div css={s.tile__warpper} className='tile__warpper'>
          {pending ? (
            <Loading />
          ) : (
            <div>
              <div className='sort__warpper' css={s.sort__warpper}>
                <div
                  className='ff ff--select form-group'
                  css={s.form__group__sort}
                  style={{paddingLeft : '1rem'}}
                >
                  <label className='label'>
                    <span>Sort by:</span>
                  </label>
                  <div className='ff__input'>
                    <select
                      className='form-control'
                      onChange={handleChange2}
                      value={sort}
                    >
                      <option value='latest'> Latest </option>
                      <option value='old'> Old </option>
                    </select>
                  </div>
                </div>

                <div
                  className='sort__result'
                  css={s.sort__result}
                >{`${showProducts.length} Result Found`}</div>
              </div>

              <div css={s.tiles} className='new__tiles'>
                {showProducts.length > 0 ? (
                  showProducts.map((item, index) => (
                    <ProductTile key={index} product={item} />
                  ))
                ) : (
                  <div style={{ margin: "auto" }}> No product </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

function sortProudcts(items, sort) {
  const _items = items;
  _items.sort((a, b) => {
    const _a = new Date(a.dateCreated);
    const _b = new Date(b.dateCreated);
    if(sort === 'latest'){
      return _b - _a;
    }
    else{
      return _a - _b;
    }
  });
  return _items;
}