/** @jsx jsx */
import { jsx } from "@emotion/core";
import cmsUtils from "../../../cms/utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../..";
import style from "./style";
import { MdOutlineArrowForward } from "react-icons/md";

export function PageTilesSmall3(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const pageTileElts = pages.map((page, index) => (
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  ));

  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles_small3",
    item.cssClass || item.anchorName || ""
  );

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.pageTiles_small3}
    >
      <div
        className='pageTiles_small3_warpper'
        css={style.pageTiles_small3_warpper}
      >
        {pageTileElts}
      </div>
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);

  return (
    <div
      className='pageTile_small3 pageTile_small3_item'
      css={style.pageTile_small3}
    >
      <SiteLink className='pageTile_small3_link' to={page.pageUrl}>
        <div style={{ overflow: "hidden", borderRadius: "20px 20px 0 0" }}>
          <div
            className='pageTile_small3__bg'
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          ></div>
        </div>

        <div className='pageTile_small3__title'>
          {page.pageTitle}
          <MdOutlineArrowForward />
        </div>
      </SiteLink>
    </div>
  );
}
