/** @jsx jsx */
import { jsx } from "@emotion/core";
import utils from "../../../utils";
import { SiteLink } from "../../../components";
import { tileStyle } from "./productTileStyle";

export function ProductTile({ product }) {
  const s = tileStyle;
  const imageUrl = product.thumbnailUrl
    ? utils.site.resourcePath(product.thumbnailUrl)
    : "/assets/product-empty.png"; //https://thp.stg5.jaba.com.au/sites/thp/media/miscellaneous/test.png
  console.log("product : ", product);
  const productUrl = product.productUrl
    ? product.productUrl
    : `/product/${product.productSlug}`;
  return (
    <div css={[s.tile]}>
      <SiteLink className="tile_link" css={s.tileLink} to={productUrl}>
        <div css={s.prdImage}>
          {/* <div css={s.bg} style={{ backgroundImage: utils.css.bgUrlStyle("https://thp.stg5.jaba.com.au/sites/thp/media/miscellaneous/test.png") }}></div> */}
          <div
            css={s.bg}
            className="tile__bg__image"
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          ></div>
        </div>

        <div className="tile__link__detail__con">
          <div css={s.name} className="tile_link_name">
            {product.productName}
          </div>
          <div css={s.shortDes} className="tile_link_des">
            {product.shortDesc}
          </div>

          <div css={s.price} className="tile_link_price">
            <span className="from">From</span>
            {utils.money.formatMoney(
              product.priceDisplay ? product.priceDisplay : product.priceIncTax
            )}
          </div>
        </div>
        
      </SiteLink>
    </div>
  );
}
