import { css } from "@emotion/core";
import { variables, mq } from "../../../cssInJs";

const numberOfTiles = [1, 2, 3, 3];
//const marginLR = [14, 14, 14, 14]
const marginTB = [14, 14, 14, 14];

const widthPercent = (index) => 100 / numberOfTiles[index] + "%";

const linkText = {
  color: variables.fontColor,
  //fontSize: '0.8rem',
  //padding: '0 20px',
  // '&:hover': {
  //   color: variables.fontColor,
  // }
};

export const tileListStyle = {
  tiles: mq({
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    //marginLeft: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    //marginRight: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    marginTop: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    marginBottom: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    // marginLeft: "-10px",
    // marginRight: "-10px",
    //maxWidth:['800px'],
    //margin: 'auto'
  }),
  categoryList: mq({
    marginTop: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    marginBottom: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    maxWidth: ["800px"],
    margin: "auto",
    "div.ff.form-group": {
      maxWidth: "250px",
    },
  }),

  p_f_warpper: {
    display: "flex",
  },

  sort__warpper: {
    display: "flex",
    fontSize: "0.8rem",
  },

  sort__result: mq({
    flex: ["0 0 30%", "0 0 50%"],
    maxWidth: ["30%", "50%"],
    paddingLeft: [0, "1rem"],
    paddingRight: [0, "1rem"],
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  }),

  form__group__sort: mq({
    flex: ["0 0 70%", "0 0 50%"],
    maxWidth: ["70%", "50%"],
    marginBottom: "0",
    ".ff__input": {
      display: "inline-block",
      marginLeft: ["6px", "1rem"],
    },
    ".form-control": {
      minWidth: "200px",
      fontSize: "0.8rem",
    },
  }),

  form__group: {
    marginBottom: "0",
    "&:not(:first-of-type)": {
      marginTop: "0.5rem",
    },
    ".form-check-input": {
      top: "7px",
      //transform: 'translateY(-50%)',
      marginTop: "0",
      accentColor: "green",
    },
    ".form-check-label": {
      lineHeight: "1.1",
      paddingTop: "3px",
      paddingBottom: "3px",
      fontSize: "0.9rem",
      "span": {
        marginLeft: "5px",
        fontSize: "0.8rem",
      },
    },
  },
};

export const tileStyle = {
  tile: mq({
    //paddingLeft: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    //paddingRight: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    paddingBottom: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    maxWidth: [
      widthPercent(0),
      widthPercent(1),
      widthPercent(2),
      widthPercent(3),
    ],
    flexBasis: [
      widthPercent(0),
      widthPercent(1),
      widthPercent(2),
      widthPercent(3),
    ],
    flexGrow: 0,
    flexShrink: 0,
    //border: '1px solid #ebebeb',
    //borderRadius: '5px'
  }),
  tileLink: {
    background: "#fff",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ebebeb",
    borderRadius: "10px",
    boxShadow: "4px 4px 14px 4px rgba(190,190,190,0.5)",
    overflow: 'hidden',
    //padding: 5,
    //border: '1px solid red',
    "&:hover": {
      textDecoration: "none",
      '.tile__bg__image' : {
        transform: "scale(1.05)",
      }
      //background: '#f3f3f3'
    },
  },
  prdImage: {
    //padding: '0 5px',
    //maxWidth: '190px',
    //margin: 'auto',
    //width: '100%',
    backgroundColor: "grey",
    overflow: 'hidden',
  },
  bg: {
    //height: 0,
    //paddingBottom: '100%',
    paddingTop: "80%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    transition: "all .2s"
  },
  shortDes: css({
    //textAlign:'center',
    marginTop: ".6rem",
    // fontSize: "0.8rem",
    color: "#212121",
    lineHeight: "1.2",
    //minHeight: '60px',
    minHeight: '48px',
    WebkitLineClamp : '3',
    overflow : 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: "vertical",
  }),
  name: css(linkText, {
    fontWeight: "bold",
    marginTop: "1rem",
    // fontSize: "0.8rem",
    //fontFamily: 'proxima-nova',
    //textAlign:'center',
    //marginBottom:'8px'
  }),
  price: css(linkText, {
    fontWeight: "bold",
    marginTop: "auto",
    // fontSize: "0.8rem",
    textAlign: "right",
    //textAlign:'center',
    //marginBottom:'1rem'
    '.from' : {
      color: "#212121",
      lineHeight: "1.2",
      fontWeight: 400,
      marginRight : '0.2rem'
    }
  }),
};
