import { css } from "@emotion/core";
import { mq } from "../../cssInJs";
//import { padding } from "polished";

export default {
  top__bar: mq({
    //position: "fixed",
    //top: ["0", null, '60px', "88px"],
    //top: ["0"],
    //backgroundColor: "#1A1A1A",
    position: ["absolute", null, "static"],
    //width: ["44px", null, "100%"],
    flexGrow: 2,
    padding: ["0"],
    //display: ['none',null,'block'],
    zIndex: 999,
    //padding: ["unset", null, null, "1rem 1rem", "1rem 2rem", "1.3rem 3rem"],
    color: "white",
    marginLeft: ["0rem", null, "2rem"],
    height: ["44px", null, "auto"],
    right: ["66px", null, "0px"],
    ".top__bar__con": mq({
      //display: ['none',null,null,'block']
    }),
  }),

  top__bar__item__con: mq({
    display: ["none", null, null, null, "flex"],
    justifyContent : 'end',
    //gap: ["1rem", null, null, null, "1.5rem", "3rem"],
    '@media (min-width: 2201px)' : {
      //gap: '6rem',
    },
  }),
  top__bar__item: {
    fontSize : '15px',
    padding : '16px 20px',
    textTransform: 'uppercase',
    borderBottom: '1px solid transparent',
    '&.selected, &:hover' : {
      borderBottom: '1px solid #78be20',
      color: '#78be20',
      'a.a_item' : {
        color: '#78be20',
        //textDecoration: 'none'
      },
      'a' : {
        //color: '#78be20',
        textDecoration: 'none'
      }
    },
    'a': {
      color: "white",
      fontSize : '15px',
      fontWeight: 600
    },
  },

  sub__item__a : {
    '&:hover, &.selected' : {
      color: '#78be20',
    }
  },

  cart__info__con: mq({
    //position: "absolute",
    //top: "50%",
    //transform: "translateY(-50%)",
    //right: ["-6px", null, "5rem", null, "3rem"],
    flexGrow : '1',
    display: 'flex',
    justifyContent: ['end',null,null,null,'center'],
    marginRight : ['4rem',null,null,null,'0px'],
    svg: {
      width: "40px",
      height: "40px",
      "&.svg__line": mq({
        width: "35px",
        height: "35px",
        marginTop: "5px",
        marginRight: ['-10px',null,'0px'] 
      }),
    },
  }),

  cart__info__link: {
    textDecoration: "none",
    "&:hover": {
      color: "white",
      textDecoration: "none",
    },
    color: "white",
    ".cart__price": mq({
      display: ["none", null, "block"],
    }),
  },

  cart__number: mq({
    top: ["6px", null, "6px"],
    left: "20px",
    position: "absolute",
    backgroundColor: "#43B02A",
    color: "white",
    borderRadius: "30px",
    lineHeight: "1",
    overflow: "hidden",
    minWidth: "25px",
    minHeight: "25px",
    display: "flex",
    border: "1px solid white",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
  }),

  top: css(
    mq({
      //height: [44, null, 80],
      height: [44, null, 88],
      background: "#000",
      position: "fixed",
      width: "100%",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s",
    })
  ),
  topScrolled: css(
    mq({
      height: [44, null, 88],
      //background: "#000"
    })
  ),
  logo: css(
    mq({
      //position : 'absolute',
      //left: '100px'
      marginLeft: ["unset", null, "100px"],
    })
  ),
  logoImg: css(
    mq({
      display: "block",
      height: [44, null, 70],
      paddingTop: [null, "3px"],
      paddingBottom: [null, "3px"],
      //marginTop: ["3rem", null, "2.5rem"],
      transition: "all .2s",
    })
  ),
  logoScrolled: css(
    mq({
      img: css(
        mq({
          //height: [38, null, 50],
          marginTop: "0rem",
        })
      ),
    })
  ),

  need__help__con: mq({
    //position: "fixed",
    //right: "100px",
    color: "white",
    display: ["none", null, null, "flex"],
    svg: {
      width: "60px",
      height: "60px",
    },
    ".need__help__t_big": {
      fontWeight: 600,
      fontSize: "1.2rem",
    },
    ".need_help_tel": {
      color: "white",
      textDecoration: "none",
      "&:hover": {
        color: "#78BE21",
      },
    },
  }),
};
