import { mq } from "cssInJs";

export default {
  card__tiles: {},
  card__tile: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    maxWidth: "952px",
    ".card__tile__link": {
      color: "white",
      "&:hover": {
        ".card__tile__bg": {
          transform: "scale(1.05)",
        },
      },
    },
    ".card__tile__bg": mq({
      aspectRatio: "8/3",
      position: "relative",
      backgroundSize: "cover",
      backgroundPosition: "center",
      transition: "all .2s",
    }),
    ".card__tile__detail": {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      display: "flex",
      flexDirection: "column",
      zIndex: 1,
    },
    ".card__tile__title": mq({
      fontSize: ["1.1rem", null, "1.5rem"],
      fontWeight: 700,
      lineHeight: "1.1",
    }),
    ".card__tile__des": mq({
      maxWidth: "420px",
      flex: "1 0 auto",
      marginTop: "1rem",
      lineHeight: "1.2",
      fontSize: ["0.9rem", null, "1rem"],
    }),
    ".card__tile__bottom": mq({
      fontSize: ["0.9rem", null, "1rem"],
      "svg": mq({
        marginLeft: "0.5rem",
        marginBottom: ["0px", null, "3px"],
      }),
    }),
  },
};
