/** @jsx jsx */
import { jsx } from "@emotion/core";
import cmsUtils from "../../../cms/utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";
import style from "./style";
import { MdOutlineArrowForward } from "react-icons/md";

export function PageTilesSmall2(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const pageTileElts = pages.map((page, index) => (
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  ));

  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles_small2",
    item.cssClass || item.anchorName || ""
  );

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      css={style.pageTiles_small2}
    >
      <div
        className="pageTiles_small2_warpper"
        css={style.pageTiles_small2_warpper}
      >
        {pageTileElts}
      </div>
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const useSlider = props.useSlider;

  return (
    <div
      className={`pageTile_small2${
        useSlider ? " useSlider" : ""
      } pageTile_small2_item`}
      css={style.pageTile_small2}
    >
      <SiteLink className="pageTile_small2_link" to={page.pageUrl}>
        <div style={{ overflow: "hidden", borderRadius: "20px 20px 0 0" }}>
          <div
            className="pageTile_small2__bg"
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          ></div>
        </div>

        <div className="pageTile_small2__title">
          {page.pageTitle}
          <MdOutlineArrowForward />
        </div>
      </SiteLink>
    </div>
  );
}
