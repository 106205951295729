import { fb, validators } from "../../../lib/form";

export function getServiceModel(data) {
  let packagesList = ["Bronze Package", "Silver Package", "Gold Package"].map(
    (x) => {
      return { name: x, value: x };
    }
  );
  packagesList = [
    { name: "Which package are you interested in?*", value: "" },
    ...packagesList,
  ];

  const model = fb.group({
    firstName: [
      data.firstName || "",
      [validators.Required()],
      { label: "First Name", type: "text" },
    ],
    lastName: [
      data.lastName || "",
      [validators.Required()],
      { label: "Last Name", type: "text" },
    ],
    phone: [
      data.phone || "",
      [validators.Required()],
      { label: "Phone Number", type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Email", type: "email" },
    ],
    packages: [
      data.packages || "",
      [validators.Required()],
      { label: null, type: "select", options: packagesList },
    ],
    enquiry: [
      data.enquiry || "",
      [validators.Required()],
      { label: "Your Message", type: "textarea" },
    ],
    attachments: [
      data.attachments || "",
      [],
      { label: "Attachments", type: "file" },
    ],
  });
  return model;
}
