//import { css } from '@emotion/core';
import variables from "../../../cssInJs/variables";
import { mq } from "../../../cssInJs";

export default {
  desc: {
    p: {
      fontSize: ".9rem !important",
      fontFamily: variables.familyBase + " !important",
      marginBottom: "0.5rem !important",
      lineHeight: "1.1 !important",
    },
  },
  heroImageContainer: mq({
    //textAlign: 'center',
    //height: ['180px', '340px'],
    //position: 'relative',
  }),
  heroImage: mq({
    //maxWidth: '350px !important',
    //height: '100% !important'
    //maxHeight: ['180px', '340px'],
    //position: 'absolute',
    //top: '50%',
    //left: '50%',
    //transform: 'translate(-50%, -50%)',
  }),
  productPageCol: mq({
    //paddingLeft: '45px',
    //paddingRight: '45px'
  }),
  productFavorite: {
    cursor: "pointer",
    "&.checked": {
      color: "red",
    },
  },
  productBreadCrumbs: mq({
    padding: "0px",
    listStyle: "none",
    marginBottom: "0.5rem",
    display: "block",
    li: mq({
      display: "inline-block",
      marginRight: "1.5rem",
      position: "relative",
      fontSize: "0.9rem",
      "&:not(:last-child)::after, &.breadCrumb.last-item::after": {
        content: '">"',
        position: "absolute",
        right: "-1rem",
      },
      "&.breadCrumb::after": {
        content: '"|"',
        position: "absolute",
        right: "-1rem",
      },
    }),
  }),
  productViewImageBg: mq({
    backgroundPosition: "center",
    backgroundSize: "contain",
    paddingTop: ["80px", "120px"],
    backgroundRepeat: "no-repeat",
    marginLeft: "5px",
    marginRight: "5px",
  }),
  productViewImages: mq({
    marginTop: ["1rem", "2rem"],
    paddingBottom: ["1rem", "2rem"],
    marginLeft: "-5px",
    marginRight: "-5px",
    ".slick-prev, .slick-next": {
      transform: "none",
      top: "150px",
      "&:before": {
        color: "#999999",
      },
    },
    ".slick-prev": {
      left: "150px",
    },
    ".slick-next": {
      right: "150px",
    },
  }),

  productViewImage: {
    //paddingLeft: '5px',
    //paddingRight: '5px'
    outline: "none",
    cursor: "pointer",
  },

  productViewShortDes: mq({
    fontSize: ["1rem"],
    textAlign: "center",
  }),

  productView__title__container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "2rem",
  },

  productViewTitle: mq({
    //fontSize: ["1.1rem", "1.3rem"],
    //textAlign: 'center',
    marginTop: "0px",
    marginBottom: "0rem",
    flex: "1 0 100%",
    maxWidth: "100%",
    display: "flex",
    alignItems: "end",
  }),

  productViewTitle__2: mq({
    fontSize: ["1.3rem"],
    //textAlign: 'center',
    marginTop: "0px",
    marginBottom: "0rem",
    //flex: '1 0 60%',
    //maxWidth : '60%',
    //display: 'flex',
    //alignItems: 'end'
  }),

  productView__each__price: {
    //flex: '1 0 40%',
    //maxWidth : '40%',
    //paddingLeft : '1rem',
    display: "flex",
    flexDirection: "row",
    "&.stick": {
      padding: "0.5rem",
    },
    //padding : '0.5rem'
    //justifyContent: 'flex-end',
    //alignItems : 'end'
    //textAlign: 'right',
  },

  addToCartBtn: {
    //width: '100%'
    "button, .btn": {
      width: "100%",
      //transform: 'skewX(-12deg)'
    },
    // 'button.gotocartbtn' : {
    //   marginTop: '1rem'
    // }
  },
  priceText: {
    fontSize: "0.8rem",
  },
  socialMedia: {
    ".social-icon-text": {
      fontSize: "0.8rem",
    },
  },
  attributesRow: {
    cursor: "pointer",
    //marginTop: '0.5rem',
    //marginBottom: '0.5rem',
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    "&:hover": {
      backgroundColor: "#999999",
      color: "white",
    },
    ".svgContainer svg": {
      transition: "all 0.5s",
    },
    ".svgContainer.open svg": {
      transform: "rotate(180deg)",
    },
  },

  attrContainer: {
    maxHeight: "0px",
    transition: "all 0.3s",
    overflow: "hidden",
    "&.open": {
      maxHeight: "1000px",
    },
  },

  desktopMobile: {
    "&.mobile": mq({
      display: ["block", "none"],
    }),
    "&.desk__top": mq({
      display: ["none", "block"],
    }),
  },
  quoteContainer: {
    marginBottom: "1rem",
    ".quote__text": {
      fontSize: "0.8rem",
      marginBottom: "1rem",
    },
    button: {
      //transform: 'skewX(-12deg)'
    },
  },
  enquiryFormContainer: {
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    zIndex: "9999",
    "form.contactForm": {
      position: "absolute",
      width: "100%",
      maxWidth: "900px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "grey",
      //paddingTop: '2rem',
      paddingLeft: "1rem",
      paddingRight: "1rem",
      margin: "0px",
      ".closeButton": {
        textAlign: "right",
        //margin: '1rem 0px',
        cursor: "pointer",
        color: "white",
        marginTop: "0.5rem",
      },
      ".enquireNowText": {
        marginBottom: "0.5rem",
        color: "white",
      },
    },
  },

  contiune__button: {
    backgroundColor: "#43B02A",
    borderColor: "#43B02A",
    fontWeight: 600,
    borderRadius: "2rem",
    padding: "0.8rem 2rem",
  },

  total__price__text: {
    display: "inline-block",
    marginRight: "1rem",
    fontWeight: "700",
    fontSize: "1.1rem",
  },

  productView__each__price__id__2: {
    //display: 'none',
    position: "sticky",
    top: "9.4rem",
    //right: '0px',
    //maxHeight: '0px',
    transition: "all 0.2s",
    //display: 'flex',
    visibility: "hidden",
    marginLeft: "auto",
    width: "max-content",
    zIndex: "1",
    //display: 'none',
    //overflow: 'hidden',
    "&.visibility.stage2": {
      //display: 'flex'
      //maxHeight: '500px',
      visibility: "visible",
    },
  },

  thank__buttons: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "-0.5rem",
    marginRight: "-0.5rem",
    ".thank__button": {
      flex: "1 0 50%",
      maxWidth: "50%",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      "button.btn": {
        width: "80%",
        backgroundColor: "white",
        color: "black",
        borderColor: "black",
        "&:focus": {
          boxShadow: "none",
          // color : 'white',
          // backgroundColor: "#43B02A",
          // borderColor : "#43B02A"
        },
        "&:hover": {
          color: "white",
          backgroundColor: "#43B02A",
          borderColor: "#43B02A",
        },
      },
    },
  },

  p_templates: mq({
    fontWeight: "700",
    //fontSize: ["1.3rem", null, null, null, "calc(100vw * 28 / 768)", "3.5rem"],
    //fontSize : ['1.3rem',null,null,'2.5rem','3.5rem']
  }),

  p_templates_img_co: mq({
    display: "flex",
    flexWrap: "wrap",
    marginTop: "1rem",
  }),

  p_templates_img_co_1: mq({
    flex: ["1 1 100%",null,null,"1 1 55%"] ,
    maxWidth:["100%",null,null, "55%"],
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#ebebeb",
    padding: "1rem",
    "& .p_templates_img_co_1_1": mq({
      flex: ["1 1 100%",null,null,null,null,"1 1 55%"],
      maxWidth: ["100%",null,null,null,null,'55%'],
    }),
    "& .p_templates_img_co_1_2": mq({
      flex: ["1 1 100%",null,null,null,null,"1 1 45%"],
      maxWidth: ["100%",null,null,null,null,'45%'],
      paddingLeft: ["0rem",null,null,null,null,"3rem"],
      marginTop : ['1rem',null,null,null,null,'0rem'],
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      ".p_templates_img_co_1_2_i": mq({
        display: "flex",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        fontSize: ['0.9rem',null,null,'1rem'],
        width: ['70%',null,null,null,'100%'],
        margin : 'auto',
        ".p_templates_img_co_1_2_i_1": mq({
          flex: ["1 1 45%", null,null,null,null, "1 1 55%"],
          maxWidth:["45%", null, null, null, null, '55%'] ,
        }),
        ".p_templates_img_co_1_2_i_2": mq({
          flex: ["1 1 55%", null,null,null,null, '1 1 45%'] ,
          maxWidth: ["55%", null,null,null,null, '45%'],
          textAlign: ['right',null,null,null,null,'left'],
          '& > div' : mq({
            display: ['inline-block',null,null,null,null,'block']
          }),
          '& > div:nth-of-type(2)' : mq({
            float: ['right', null, null, null, null, 'none']
          })
        }),
      }),
    }),

    '&.current__stage__1' : {
      flex: '1 1 100% ',
      maxWidth: '100%'
    }

  }),

  p_templates_img_co_2: mq({
    flex: ["1 1 100%",null,null,"1 1 45%"],
    maxWidth: ["100%",null,null,"45%"],
    //border: "1px solid red",
    padding: ["1rem 0rem",null,null,"1rem 3rem"],
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    '&.current__stage__1' : {
      flex: '1 1 100%',
      maxWidth: '100%',
      paddingLeft : '0',
      paddingRight: '0'
    }

  }),

  buttons : {
    display: 'flex',
    gap : '30px',
    'a' : mq({
      width: '100%',
      textAlign: 'center',
      //backgroundColor : 'transparent !important',
      //border : '1px solid black',
      //borderRadius: '10px !important',
      //color : 'black !important',
      //fontWeight: '300 !important',
      paddingTop : '0.2rem !important',
      paddingBottom : '0.2rem !important',
      backgroundColor: "#43B02A !important",
      borderColor: "#43B02A !important",
      display: 'flex',
      justifyContent: 'center',
      alignItems : 'center',
      fontSize: ['0.9rem',null,null,'1rem'],

      '&:hover' : {
        backgroundColor: "#0e0e0e !important",
        borderColor: "#080808 !important",
      }

    })
  }

};
