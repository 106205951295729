//import variables from "../../../../cssInJs/variables";
//import { mq } from "../../../../cssInJs";

export default {
  popup__common: {
    position: "relative",
    "svg.close__btn": {
      position: "absolute",
      top: "5px",
      right: "10px",
      fontSize: "1.5rem",
      cursor: "pointer",
    },
    ".popup__common__title": {
      padding: "1rem",
      fontSize: "1.1rem",
      fontWeight: 700,
    },
  },
  popup__almost__finished: {
    ".button__container": {
      display: "flex",
      flexDirection: "row",
      marginLeft: "-0.5rem",
      marginRight: "-0.5rem",
      ".button__1": {
        flex: "1 0 50%",
        maxWidth: "50%",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        "button.btn": {
          width: "100%",
          "&:focus": {
            boxShadow: "none",
          },
        },
        "&.selected button.btn": {
          backgroundColor: "#43B02A",
          borderColor: "#43B02A",
          color: "white",
          "&:focus": {
            boxShadow: "none",
          },
        },
      },
    },
  },
};