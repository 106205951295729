import { css } from '@emotion/core'
import { mq } from '../../../cssInJs'

export default{
    testimonialItem : css(mq({
        maxWidth : '330px',
        backgroundColor: '#f8f8ff',
        opacity: '.9',
        textAlign: 'center',
        color: '#000',
        padding: '50px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: 'auto',
        // height: ['400px', '430px']
    })),


    testimonialItem_title : css(mq({
        marginBottom: '0px',
        fontSize: ['2rem', '1.72rem']
    })),

    testimonialItem_desc :css(mq({
        marginBlock: '1rem',
        flex: '1 1 auto',
        fontSize: ['1.2rem', '1rem']   
        //flexGrow: '1',
        //flexShrink: '1'
    })),

    testimonialItem_bottom : css(mq({
        fontSize : ['1rem', '0.86rem'],
        fontWeight: '600'
    }))
}