//import variables from "../../../../cssInJs/variables";
//import { mq } from "../../../../cssInJs";

export default {
  popup__common: {
    position: "relative",
    "svg.close__btn": {
      position: "absolute",
      top: "5px",
      right: "10px",
      fontSize: "1.5rem",
      cursor: "pointer",
    },
    ".popup__common__title": {
      padding: "1rem",
      fontSize: "1.1rem",
      fontWeight: 700,
    },
  },
  design__review: {},
  design__review__co: {
    flexWrap: "wrap",
    display: "flex",
    marginLeft: "-0.5rem",
    marginRight: "-0.5rem",
    ".design__review__img": {
      flex: "1 0 50%",
      maxWidth: "50%",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    ".design__review__contetns": {
      flex: "1 0 50%",
      maxWidth: "50%",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
  },

  design__review__title: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },

  design__review__des: {
    svg: {
      marginRight: "0.5rem",
    },
    "&.top__title": {
      marginBottom: "0.2rem",
      marginTop: "0.5rem",
    },
    "&.check__title": {
      fontSize: "0.9rem",
    },
  },
  buttton1: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    paddingTop: "0.1rem",
    paddingBottom: "0.1rem",
    borderRadius: "20px",
  },

  buttton2: {
    width: "100%",
    backgroundColor: '#43B02A',
    borderColor : '#43B02A',
    //color: 'black',
    paddingTop: "0.1rem",
    paddingBottom: "0.1rem",
    borderRadius: "20px",

    "&.abled": {},
    "&.disabled": {},
    "&::disabled": {},
  },
  buttton3__container : {
    marginTop : '2rem',
    textAlign: 'center',
  },
  buttton3 : {
    '&.selected' : {

    }
  },

  design__review__hr: {
    borderColor: "rgba(0, 0, 0, 0.6)",
  },

  design__review__form__check: {},
};
