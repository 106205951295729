export default {
  items: {
    marginBottom: '2rem'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px #ccc',
    padding: '0.5rem',
    transition: 'all 0.5s',
    '&:hover': {
      background: '#f3f3f3'
    }
  },
  // imgContainer: {
  //   flex: '1 1 auto',
  // },
  product: {
    flex: '1 1 auto',
  },
  details: {
    flex: '0 1 auto',
    display: 'flex',
  },
  optionCon : {
    display: 'flex',
  },
  optionName: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    flex : '1 0 30%',
    maxWidth: '30%'
  },
  optionValue: {
    fontSize: '0.9rem',
  },
  qty: {
    margin: '0 0.5rem',
    '.form-group': {
      display: 'inline-block',
    },
    'input': {
      width: '3.5rem'
    }
  },
  amount: {
    margin: '0 0.5rem',
    paddingTop: '0.3rem',
    minWidth: '5rem',
    textAlign: 'right'
  },
  remove: {
    //margin: '0 0.5rem',
    //paddingTop: '0.1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    'button' : {
      display: 'block',
    }
  }
  
}