/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";
import env from "../../../../env";
import { useEffect, useState } from "react";
import { Loading, Button } from "../../../../components";

export function AddedToCartView({ changeOptionItems, option }) {
  console.log("option ---- ", option);
  const [detail, setDetail] = useState();

  useEffect(() => {
    //const quantityItem = option.options.find((x) => x.title.toLowerCase() === 'quantity');
    const _data = {
      quantity: null,
      cardSize: null,
      printing: null,
      total: null,
    };
    option.options.forEach((el) => {
      if (
        el.title.toLowerCase() === "quantity" &&
        el.quantity &&
        el.quantity !== ""
      ) {
        _data.quantity = el.quantity;
      } else if (
        el.title.toLowerCase() === "card size" &&
        el.value &&
        el.value !== ""
      ) {
        _data.cardSize = el.value;
      } else if (
        el.title.toLowerCase() === "printing" &&
        el.value &&
        el.value !== ""
      ) {
        _data.printing = el.value;
      }
    });

    _data.total = parseFloat(option.totalEachPrice) * parseInt(_data.quantity);
    setDetail({ ..._data });

    // eslint-disable-next-line
  }, []);

  if (!detail) {
    return <Loading />;
  }

  return (
    <div
      className="popup__common"
      css={[style.popup__common, style.added__view]}
    >
      <div className="popup__common__title">
        A new product has been added to your cart
      </div>

      <div className="product__info" css={style.product__info}>
        <div className="product__name">{option.productName}</div>
        <div
          className="product__info__container"
          css={style.product__info__container}
        >
          <div className="product__info__img" css={style.product__info__img}>
            <img
              src={env.resourceBase + option.thumbnailUrl}
              width="100%"
              alt="logo"
            />
          </div>
          <div
            className="product__info__detail"
            css={style.product__info__detail}
          >
            <div
              className="product__info__detail__item"
              css={style.product__info__detail__item}
            >
              <div className="product__info__detail__item_1">Quantity :</div>
              <div className="product__info__detail__item_2">
                {detail.quantity}
              </div>
            </div>

            <div
              className="product__info__detail__item"
              css={style.product__info__detail__item}
            >
              <div className="product__info__detail__item_1">Size :</div>
              <div className="product__info__detail__item_2">
                {detail.cardSize}
              </div>
            </div>

            <div
              className="product__info__detail__item"
              css={style.product__info__detail__item}
            >
              <div className="product__info__detail__item_1"> Printing : </div>
              <div className="product__info__detail__item_2">
                {" "}
                {detail.printing}{" "}
              </div>
            </div>

            <div
              className="product__info__detail__total"
              css={style.product__info__detail__total}
            >
              <div
                style={{
                  display: "inline-block",
                  paddingLeft: "0.2rem",
                  paddingRight: "0.2rem",
                  fontWeight: 700,
                }}
              >
                Total :{" "}
              </div>
              <div
                style={{
                  float: "right",
                  paddingLeft: "0.2rem",
                  paddingRight: "1rem",
                }}
              >
                {"$" + detail.total}{" "}
              </div>
            </div>
          </div>
        </div>

        <div style={{textAlign : 'right', marginTop : '1.5rem'}}>
          <Button css={style.contiune__button} onClick={() => {
            changeOptionItems("gotoshopping");
          }}>
            Continue Shopping
          </Button>
          <Button css={style.contiune__button} onClick={() => {
            changeOptionItems("gotocart");
          }}>
            Checkout
          </Button>
        </div>

      </div>
    </div>
  );
}