/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, Fragment } from "react";
import { SiteLink, useRouter } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";
//import { HiOutlineShoppingBag } from "react-icons/hi2";
import { ReactComponent as YourSvg } from './Icon-Shopping-bag.svg';
import { PiLineVertical } from "react-icons/pi";
//import { MdOutlineQuestionAnswer } from "react-icons/md";
//import { MdKeyboardArrowDown } from "react-icons/md";
import { useStore } from "../../store";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { pathname } = useRouter();
  const { state } = useStore();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }

  return (
    <Fragment>
      <header
        css={[
          style.top,
          (scrolled ||
            pathname.includes("/product/") ||
            pathname.includes("/order-thankyou")) &&
            style.topScrolled,
        ]}
        id="top"
      >
        <BackButton />
        <SiteLink
          to="/"
          css={[
            style.logo,
            (scrolled ||
              pathname.includes("/product/") ||
              pathname.includes("/order-thankyou")) &&
              style.logoScrolled,
          ]}
        >
          <img
            css={style.logoImg}
            src={
              scrolled && style.logoScrolled
                ? "/assets/logos/logo.png"
                : "/assets/logos/logo.png"
            }
            alt="logo"
          />
          {/*<img css={style.logoImg} src="/assets/logos/logo.png" alt="logo" />*/}
        </SiteLink>

        <div css={[style.top__bar]}>
          <div
            style={{
              position: "relative",
              height: "100%",
              // maxWidth: "2000px",
              margin: "auto",
              //paddingInline: "1rem",
            }}
            className="top__bar__con"
          >
            <div css={style.top__bar__item__con}>
              <div
                css={style.top__bar__item}
                className={`${pathname === "/" ? "selected" : ""}`}
              >
                <SiteLink to="/" className="a_item">
                  {" "}
                  Home{" "}
                </SiteLink>
              </div>
              <div
                css={style.top__bar__item}
                className={`top_bar_main ${
                  pathname === "/online-order" ||
                  pathname === "/products/business-cards" ||
                  pathname === "/products/flyers" ||
                  pathname === "/products/posters" ||
                  pathname === "/products/signage"
                    ? "selected"
                    : ""
                }`}
                style={{ position: "relative" }}
              >
                <SiteLink to="/online-order" className="a_item">
                  Online Order
                  {/* <MdKeyboardArrowDown size={30} /> */}
                </SiteLink>

                <div className="top_bar_sub">
                  <div className="top_bar_sub_products">
                    <a
                      className={`${
                        pathname === "/products/business-cards"
                          ? "selected"
                          : ""
                      }`}
                      css={style.sub__item__a}
                      href="/products/business-cards"
                    >
                      Business Cards
                    </a>
                    <a
                      className={`${
                        pathname === "/products/flyers" ? "selected" : ""
                      }`}
                      css={style.sub__item__a}
                      href="/products/flyers"
                    >
                      Flyers
                    </a>
                    <a
                      className={`${
                        pathname === "/products/posters" ? "selected" : ""
                      }`}
                      css={style.sub__item__a}
                      href="/products/posters"
                    >
                      Poster
                    </a>
                    <a
                      className={`${
                        pathname === "/products/signage" ? "selected" : ""
                      }`}
                      css={style.sub__item__a}
                      href="/products/signage"
                    >
                      Signage
                    </a>
                  </div>
                </div>
              </div>

              <div
                css={style.top__bar__item}
                className={`${pathname === "/services" ? "selected" : ""}`}
              >
                <SiteLink to="/services" className="a_item">
                  {" "}
                  Services{" "}
                </SiteLink>
              </div>
              <div
                css={style.top__bar__item}
                className={`${
                  pathname === "/artwork-services" ? "selected" : ""
                }`}
              >
                <SiteLink to="/artwork-services" className="a_item">
                  {" "}
                  Artwork{" "}
                </SiteLink>
              </div>
              <div
                css={style.top__bar__item}
                className={`${pathname === "/about" ? "selected" : ""}`}
              >
                <SiteLink to="/about" className="a_item">
                  {" "}
                  About Us{" "}
                </SiteLink>
              </div>
              <div css={style.top__bar__item}>
                <SiteLink to="#contact"> Contact Us </SiteLink>
              </div>
            </div>
          </div>
        </div>

        <div className="cart__info__con" css={style.cart__info__con}>
          <SiteLink to={"/cart"} css={style.cart__info__link}>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <PiLineVertical className="svg__line" />
              <div style={{ position: "relative" }}> 
                <YourSvg />
                <div className="cart__number" css={style.cart__number}>
                  {state.cartInfo ? state.cartInfo : 0}
                </div>

              </div>

              <div className="cart__price" style={{ marginLeft: "1rem" }}>
                <div>${state.cartItemAmount ? state.cartItemAmount : 0}</div>
              </div>
            </div>
          </SiteLink>
        </div>

        {/* <div className='need__help__con' css={[style.need__help__con]}>
          <MdOutlineQuestionAnswer />
          <div style={{ marginLeft: "1rem" }}>
            <div className='need__help__t_big'>NEED HELP?</div>
            <div>
              <a className='need_help_tel' href='tel:0883499999'>
                <span>Call us at (08) 8349 9999</span>
              </a>
            </div>
          </div>
        </div> */}

        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}
