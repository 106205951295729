/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react';
//import style from './shippingStyle';
import { Button, ErrorMessage } from '../../../components';
import { CartSummary } from '../cartSummary/summary';
import utils from '../../../utils';

export function Shipping({ cart, form, saveShipping, post }) {
  const model = form.model;
  const render = (name) => form.renderControl(name);

  const val = form.getValue();
  const billingInfo = model.find('billingInfo');
  const shippingInfo = model.find('shippingInfo');

  const next = (e) => {
    form.validateForm(e, () => {
      saveShipping();
      utils.ui.scrollTo('#ecmcart');
    })
  }
  
  if (billingInfo){
    billingInfo.disableValidators = val.useSameAddress;
  }
  
  shippingInfo.disableValidators = false;

  if(val.shippingMethod === 'Pickup' || val.shippingMethod === '0'){
    shippingInfo.disableValidators = true;
  }

  return <div>
    <div className="row" >
      <div className="col-md-9">
        <form>
          <h3>Delivery Method</h3>
          {render('shippingMethod')} 
          {
            val.shippingMethod !== '' &&
            <Fragment> 
              {
                val.shippingMethod === 'Standard' &&
                <Fragment>
                  {/* <h3>Shipping Country</h3>
                  {render('shippingCountry')} */}
                  <h3>Shipping Address</h3>
                  <Address form={form} addressModel={shippingInfo} />
                </Fragment> 
              }
              <h3>Billing Address</h3>
              {
                val.shippingMethod === 'Standard' &&
                <Fragment>
                  {render('useSameAddress')}
                </Fragment> 
              }
              { 
                !val.useSameAddress &&
                <Address form={form} addressModel={billingInfo} />
              }
            </Fragment>
          }

        </form>
      </div>

      <div className="col-md-3">
        <CartSummary cart={cart} />
      </div>
    </div>
    
    {
      val.shippingMethod !== '' &&
      <Fragment>
        <div className="form__actions">
          <Button onClick={next} status={form.status}>Next</Button>
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors}/>
        </div>
      </Fragment>
    }

  </div>
}

function Address({form, addressModel}) {
  const render = (name) => form.renderControl(name, addressModel);
  return <div>
    <div className="row">
      <div className="col-12 col-md-6">
        {render('firstName')}
      </div>
      <div className="col-12 col-md-6">
        {render('surname')}
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        {render('street1')}{render('street2')}
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-4">
        {render('suburb')}
      </div>
      <div className="col-12 col-md-4">
        {render('state')}
      </div>
      <div className="col-12 col-md-4">
        {render('postcode')}
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-6">
        {render('email')}
      </div>
      <div className="col-12 col-md-6">
        {render('mobile')}
      </div>
    </div>
  </div>
}